.p-flex-wrap.w--100{
    display: flex;
    align-items: center;
}
.custom-rating {
    display: flex;
    align-items: center;
}

.icon {
    font-size: 26px;
    color: #b86060;
    margin-right: 3px;
}

.full {
    color: #f0c040; 
}
.avatar{
    background: 
        no-repeat 
        url("../../assets/images/avatar_user.png");
    background-size: 32px 30px;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 50%;
    background-color: #000000;
}
.evaluate-boxshadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}
.evaluate-boxshadow-title {
    background-color: #DFEDF7; 
    box-shadow: 0 4px 0 #cadde9;
    border-radius: 3px 3px 0 0;
    margin-bottom: 2px;
}

// Start define rating follow stars
.box-star {
    width: 450px;
    position: relative;
    z-index: 999;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0 15px;
    border-radius: 10px;
    display: none;
    top: 20px;
    top: -310%;
    left: -78px;
}

.parent-show{
    position: relative;
}
.parent-show:hover>.box-star{
    display: block;
}

.box-star--above {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-star:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 90px;
    border-top: 10px solid #ffffff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

.box-star .point p {
    color: #ff9f00;
    font-size: 28px;
    font-weight: bold;
    line-height: 28px;
    margin-right: 6px;
    margin-bottom: 0;
}

.rate-list{
    margin-top: 20px;
    width: 70%;
    padding: 0 0 0 10px;

    li{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }

    .timeline-star {
        background-color: #eee;
        border-radius: 20px;
        height: 6px;
        position: relative;
        width: calc(100% - 40%);
    }
    .timing {
        background-color: #ff9f00;
        border-radius: 20px;
        left: 0;
        height: 6px;
        position: absolute;
        top: 0;
    }
    .number-star {
        width: 35px;
    }
    .number-percent {
        width: 35px;
    }
}
button.join-evaluate{
    border: 1px solid #20bd2d;
    background-color: #20bd2d;
    width: 100%;
    padding: 10px 0;
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 0 7px 0 #1d9627;
    transition: all linear 0.2s;
}
button.join-evaluate:active{
    transform: translateY(8px);
    box-shadow: none;
}
// End define rating follow stars


// Start Custome Scroll 
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: #3BC5FC; 
    // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3BC5FC; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3BC5FC; 
}
// End Custome Scroll 
.scroll-data-evaluate {
    max-height: 520px;   
    overflow-y: auto;
    margin: 5px 0 10px 0;
}
.p-d-flex.p-justify-between.p-ml-4.p-mr-4>p.pointer:hover{
    text-decoration: underline;
}

@media (max-width: 1024px ) {
    .icon {
        font-size: 18px;
    }
    
    .pi.pi-eye.icon-medium.pointer.text-primary {
        font-size: 18px !important;
    }
    .box-star{
        width: 400%;
        top: -370%;
        left: -84px;
        .point p {
            font-size: 20px;
            margin-right: 0;
        }
        .point span.amount-evaluate {
            font-size: 12px;
            margin-right: 0;
        }
    }
    .rate-list {
        margin: 15px 0 5px;
        .timeline-star {
            width: calc(100% - 100px);
        }
    }
    div.max-line-3{
        font-size: 12px !important;
    }
    td p.p-p-0.p-m-0 {
        font-size: 12px !important;
    }
}

@media (max-width: 977px) {
    .icon {
        font-size: 16px;
    }
    .box-star{
        top: -395%;
        left: -86px;
    }
}
@media (max-width: 915px) {
    .box-star{
        top: -260%;
        left: -78px
    }
}
@media (max-width: 768px) {
    .p-button.p-component.p-button-primary.w-xs-100.w-md-auto {
        margin: 10px 5px;
    }
    .action-table.p-text-center{
        .p-column-title {
            text-align: left;
        }
        .p-d-flex.p-jc-center{
            align-items: center;
            justify-content: initial !important;
        }
    }
    
}
@media (max-width: 635px) { 
    .p-calendar.p-component.p-inputwrapper.p-mr-2.p-calendar-w-btn {
        margin-top: 10px;
    }
}
