.import-room-meeting-dialog{
    min-width: 600px !important;
}

.import-room-meeting-dialog__header-title{
    font-size: 16px !important;
    font-weight: 600;
}

.import-room-meeting-dialog__header-text{
    font-weight: 400;
    font-size: 14px !important;
}

.import-room-meeting-dialog__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
}

.import-room-meeting-dialog__import-btn{
    color: #fff;
    background-color: #0dac50 !important;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    background-color: transparent;
    border: 1px solid transparent;
    white-space: nowrap;
    font-size: 14px !important;
    line-height: 1.8rem;
    padding: 4px 16px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: none;
    text-shadow: none;
}

.import-room-meeting-dialog__import-file{
    overflow: hidden;
    margin: 0!important;
    padding: 4px 6px;
    background: #ebf7fb;
    border: 1px solid #c5e7f2!important;
    min-height: inherit;
    line-height: inherit;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px !important;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.import-room-meeting-dialog__import-file > span{
    display: flex;
    align-items: center;
    gap: 6px;
}

.import-room-meeting-dialog__import-file--errors{
    margin: 12px 0;
    font-size: 14px;
    color: red;
}

.import-room-meeting-dialog__import-file--errors-item{
    font-size: 13px;
}
.sample-file>a{
    color: #000000;
}
.sample-file:hover{
    text-decoration: underline;
}
