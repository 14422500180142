.page-error {
    position: relative;
    min-height: var(--min-height-content);
    background-size: cover;
    .pg-white {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    }
    &__header {
        font-size: 8rem;
    }
    &__body {
        font-size: 2rem;
    }

    &__404 {
        background-color: var(--surface-b);
        img{
            margin-bottom: -9.375rem;
        }
    }
    &__500,
    &__403 {
        img {
            margin-bottom: -1.875rem;
        }
        background-color: var(--surface-b);
    }
}

.button-9 {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer !important;
  margin-top: 40px;
}

.button-9:disabled {
  cursor: default;
}

.button-9:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}
