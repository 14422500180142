@import "./_overrides";
@import "../app/App.scss";
@import "./_data_table_demo";
@import "./_display_visible";
@import "./_tree_table";
@import "./_custom_responsive";
@import "./_schedule";

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;

    --color-background-gray: #efefef;
    --surface-a: #3BC5FC;
    --surface-ab: #83c5d3;
    --surface-atbody: #2cbcf5;
    --surface-ad: #25b4d1;
    --surface-button: #09abcc;
    --surface-button-bold: #03afd1;
    --surface-text-ad: #0192af;
    --height-header-footer: 4rem;
    --min-height-content: 88.5vh;
    --height-footer: 4rem;
}
.layout-topbar {
    height: var(--height-header-footer) !important;
    padding: 0 15px;
    padding: 0 1.3rem;
    border-bottom: none;
}

// .layout-content {
//     margin-left: 0;
//     .content-section {
//         min-height: var(--min-height-content);
//         padding: 1.6rem !important;
//     }
//     .card {
//         padding: 1.3rem;
//     }
// }
.p-menubar {
    background-color: var(--white);
    .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #ecece2;
    }
}

.p-chips {
    .p-chips-multiple-container {
        .p-chips-token {
            margin-top: 0.2rem;
        }
    }
}
.p-submenu-list {
    width: auto !important;
    white-space: nowrap;
}

.p-filter-column {
    background-color: #ffffff !important;
}
// css nút dropdow
.p-paginator {
    padding: 0;
    .p-dropdown {
        margin-right: 0.5rem;
        div {
            span {
                color: var(--primary) !important;
            }
        }
    }
}
@mixin block-ellipsis($row) {
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.max-line-3 {
    @include block-ellipsis(3);
    span {
        @include block-ellipsis(3);
    }
}
.max-line-2 {
    @include block-ellipsis(2);
}
// table dài tự động xuống dòng
.table-word-break {
    .p-datatable-tbody {
        word-break: break-word !important;
    }
    .p-treetable-tbody {
        word-break: break-word !important;
    }
}
.break-word {
    word-break: break-word !important;
}
.p-toolbar {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: #3BC5FC;
    // border-left:none ;
    // border-right:none ;
    // border-radius: 0;
    background-color: var(--surface-atbody);
}

.p-checkbox {
    .p-checkbox-box.p-highlight {
        border-color: var(--surface-c) !important;
        background: var(--surface-a) !important;
    }
}

.with-3 {
    width: 3%;
}

.with-10 {
    width: 10%;
}

.icon-medium {
    font-size: 1.4em;
}
.icon-small {
    font-size: 0.9em;
}

.text-danger {
    color: var(--danger);
}
.text-success {
    color: var(--success);
}
.text-primary {
    color: var(--primary);
}
.text-warning {
    color: var(--warning);
}
.text-secondary {
    color: var(--white);
}
.p-tag-secondary {
    background-color: var(--secondary);
}
.text-black {
    color: #000;
}
.text-white {
    color: var(--white);
}
.pointer {
    cursor: pointer !important;
}

.text-normal {
    font-weight: normal;
    padding: 0;
    /* padding-right: 0; */
}

.textarea-custom {
    overflow-y: scroll !important;
    max-height: 6rem !important;
}

.hide {
    display: none;
}
.show {
    display: block;
}
.on-show {
    display: flex;
}
.on-hide {
    display: none;
}

.p-tag.p-tag-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
}
.p-dialog-mask {
    z-index: 997 !important;
}
@mixin p-accordion-color($bg, $color) {
    background: $bg;
    border-color: $bg;
    color: $color;
}

// css lại thanh accordion làm thêm bước
.p-accordion--custom {
    .p-accordion-header {
        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                @include p-accordion-color(var(--surface-ab), var(--surface-text-ad));
                &:hover {
                    @include p-accordion-color(var(--surface-a), var(--surface-text-ad));
                }
                border-color: var(--surface-a);
            }
        }
        &:not(.p-highlight):not(.p-disabled):hover {
            .p-accordion-header-link {
                @include p-accordion-color(var(--surface-a), var(--surface-text-ad));
            }
        }
        .p-accordion-header-link {
            @include p-accordion-color(var(--surface-ab), var(--surface-text-ad));
            border-color: var(--surface-a);
        }
    }
    .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        box-shadow: 0 0 0 0.2rem var(--surface-a);
    }
}
.label-bold {
    font-weight: 500;
}

.stt-table {
    width: 3.5rem !important;
    text-align: center !important;
}

svg {
    vertical-align: bottom;
}
.cursor-help {
    cursor: help;
}
.p-invalid {
    color: #dc3545;
}

@each $fw in $fontWeight {
    .p-text-fw-#{$fw} {
        font-weight: $fw;
    }
}

.w-auto {
    width: auto !important;
}
.btn-handle {
    height: 1.5rem !important;
    width: 1.5rem !important;
}
.width-lock {
    width: -webkit-fill-available;
}
// $spacing: ;

.p-mb-n1 {
    margin-bottom: -0.5rem !important;
}
.p-tree-selectable {
    max-height: 50vh;
    overflow-y: auto;
}

.p-margin-r {
    justify-content: end;
}

.input-scroll > ul.p-inputtext {
    overflow-y: auto !important;
    max-height: 6.5rem;
}
.p-disabled:not(button, .p-button) {
    opacity: 1 !important;
    background-color: var(--surface-c);
    cursor: no-drop !important;
}
// bỏ margin scroll table
.p-datatable-scrollable-header-box,
.p-treetable-scrollable-header-box {
    margin-right: 0 !important;
}
.font-size-20 {
    font-size: 20px;
}
.font-size-17 {
    font-size: 17px;
}
.font-size-15 {
    font-size: 15px;
}
.box-disabled {
    font-size: 1rem;
    color: #495057;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-radius: 4px;
    overflow: auto;
    opacity: 1 !important;
    background-color: var(--surface-c);
    // cursor: no-drop !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
    box-shadow: none !important;
}
