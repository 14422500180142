.agency-active--user {
    p {
        font-size: 13px;
    }

    b{
        font-size: 13px;
    }
}
.logo-agency {    
    width: 80px;
    height: 80px;
    border: 1px solid #ccc;
}
.p-logo-agency {
    padding: 18px 10px 10px 10px,
}
.border-bottom{
    border-bottom: 1px solid #ccc;
}
.p-paginator.p-component.p-paginator-bottom {
    padding: 20px 0;
    justify-content: end;
}
