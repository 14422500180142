.page-error {
    min-height: var(--min-height-content);
    color: white;
    position: relative;
    background-size: cover;
    .pg-white {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    }
    &__header {
        font-size: 8rem;
    }
    &__body {
        font-size: 2rem;
    }

    &__404 {
        background-color: #105191;
        .btn-err {
            background-color: rgba(255, 255, 255, 0.1);
            border: none;
            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
    &__403 {
    }
    &__500,
    &__403 {
        background-color: #be4236;
        .btn-err {
            background-color: rgba(255, 255, 255, 0.1);
            border: none;
            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}
