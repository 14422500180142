.p-dialog-mask .schedule__dialog.p-dialog .p-dialog-content{
    padding-top: 0 1rem !important;
}

.schedule__dialog{
    max-width: 920px !important;
    width: 1080px;
}

.schedule-right{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fbfbfe!important;
}

.schedule__right-title{
    font-weight: 600;
    font-size: 15px;
    padding: 8px 0;
}

.schedule__add-element{
    padding: 12px 24px;
    color: #2970f6;
    cursor: pointer;
}

.schedule__member-popover{
    position: fixed !important;
    transform: translate(-512px, 36px) !important;
    // padding: 40px 0;
    height: 320px;
    max-width: 432px;
    border: 1px solid #ddd;
    background: #fff;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    border-radius: 6px;
}

.schedule__member-popover .popover-arrow{
    bottom: 0px !important;
    right: 0px !important;
    top: unset !important;
    width: 14px;
    height: 14px;
    transform: translate(8px, -130px) rotate(45deg) !important;
    background: #fff;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    z-index: -99;
}

.schedule__member-popover-search{
    display: flex;
    gap: 8px;
}

.schedule__member-popover-search__form{
    position: relative;
    display: flex;
}

.schedule__member-popover-search__input{
    padding: 8px 16px 8px 32px;
    margin: 0;
    background: #fff;
    color: #333;
    font-size: 1em;
    border-radius: 4px;
    min-height: 36px;
    outline: none;
    border: 1px solid #dee2e6!important;
    width: 340px;
}

.schedule__member-popover-search__input::placeholder{
    font-size: 14px;
}

.schedule__member-popover-search__icon{
    position: absolute;
    top: 8px;
    left: 8px;
}

.schedule__member-popover-search__group{
    padding: 8px;
    border: 1px solid #dee2e6!important;
    border-radius: 6px;
    cursor: pointer;
}

.schedule__member-popover-content__not-found-result{
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.schedule__member-popover-content__result-header{
    text-transform: uppercase;
    padding: 0 12px;
    color: #000;
    font-weight: 400;
    margin-bottom: 8px;
}

.schedule__member-popover-content__result-item{
    // background-color: #dee2e6;
    background-color: #F9F9F9;
    cursor: pointer;
}

.schedule__member-popover-content__result-item.active{
    background-color: #2970f6;
    color: #fff;
}

.schedule__member-popover-content__result-item:hover{
    background-color: #2970f6;
    color: #fff;
}

.schedule__member-popover-content__result-item__info-name{
    font-size: 12px;
}

.schedule__member-popover-content__result-item__info-email{
    font-size: 12px;
}

.group-user__modal{
    max-width: 720 !important;
    width: 720px;
    z-index: 2000 !important;
}

.schedule__member-popover-search__input.input-lg{
    width: 440px;
}

.schedule__member-popover-search__input.input-unset-width{
    width: unset;
}

th.table-theme__header-start{
    border: none !important;
    border-top-left-radius: 8px;
}

th.table-theme__header-end{
    border: none !important;
    border-top-right-radius: 8px;
}

.p-datatable .p-datatable-thead tr th{
    border: none !important;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.schedule__member-popover-content__result-body{
    max-height: 320px;
    overflow: auto;
}

.p-dialog-mask[style*="z-index: 1101;"] {
    z-index: 999 !important;
}

.p-dialog-mask[style*="z-index: 1102;"] {
    z-index: 1001 !important;
}

#user_list{
    background-color: unset !important;
    cursor: pointer !important;
}

.schedule__group-user__table-container tr{
    transition: transform 2s ease-in-out;
}

.schedule__group-user__table-container .p-checkbox:not(.p-checkbox:disabled) .p-checkbox-box.p-focus{
    box-shadow: unset !important;
    border-color: #ced4da !important;
}

.css-1xc3v61-indicatorContainer{
    color: #495057 !important;
}

.css-1u9des2-indicatorSeparator{
    width: unset !important;
}

.suggest-user-list-other{
    z-index: 9000 !important;
    position: absolute;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    max-height: 180px !important;
    top: 68px;
    background-color: #fff;
    border-radius: 8px;
    overflow: auto;
    padding: 4px 0;
}

.suggest-user-list-other > span{
    padding: 8px 20px;
    cursor: pointer;
}

.suggest-user-list-other > span:hover{
    background-color: #E4E4D0;
}

