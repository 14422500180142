.home {
    .features {
        min-height: var(--min-height-content) !important;
    }
    .title-chart {
        transform: translateX(12px);
        text-align: left;
    }
    .sub-title-chart {
        font-style: italic;
        font-size: 11px;
        transform: translate(12px, 35px);
    }
    span.sub-title-chart-detail {
        font-size: 10px;
        font-weight: normal !important;
        span {
            font-style: italic;
        }
    }
    .btn-search-home {
        padding: 0 27px;
    }
    .apexcharts-legend.apexcharts-align-center.apx-legend-position-left {
        top: 45px !important;
        text-align: left !important;
    }
    .pie-chart .apexcharts-legend-series>span{
        font-size: 14px !important;
    }
    .pie-chart .apexcharts-legend-series {
        padding-bottom: 5px !important;
    }
    .form-search-chart{
        gap: 10px;
    }
    .p-fsw-18 {
        font-size: 20px;
        font-weight: 500;
    }
}
