.working-day-config{
    padding: 12px 0;
}

.working-day-config-table{
    width: 100% !important;
    border-collapse: collapse;
    padding: 0 20px;
}

.working-day-config-table thead tr{
    background: rgb(249,250,254);
}

.working-day-config-table th{
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #343a40;
    background: rgb(249,250,254);
    transition: box-shadow 0.2s;
    width: calc(100% / 8);
}

.working-day-config-table td{
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    background: rgb(255,255,255);
    transition: box-shadow 0.2s;
}

.working-day-config-table td div{
    padding: 12px 0;
    background-color: rgb(187,247,187);
    align-items: center;
    display: flex;
    width: 100% !important;
    min-width: 100% !important;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.working-day-config-table td div.not-active{
    background-color: rgb(255,199,210) !important;
}
