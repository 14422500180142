@import "./_overrides";

//css chữ cái đầu Tháng`
.fc-toolbar-title::first-letter {
    text-transform: uppercase;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #2196f3!important;
    border-color: #2196f3!important;
    color: #ffffff!important;
    &:hover {
        background: #0d89ec!important;
        border-color: #0d89ec!important;
        color: #ffffff!important;
    }
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #ffffff!important;
    border: 1px solid #ced4da!important;
    color: #495057!important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    &:hover {
        background: #e9ecef!important;
        border-color: #ced4da!important;
        color: #495057!important;
    }
}
.fc-event {
    border-radius: 3px;
}
// css lịch
.schedule {
    .p-inputgroup-addon {
        background: #007bff;
        color: #ffffff;
    }
    .p-inputgroup-addon {
        border: none;
    }
    .p-inputgroup .p-inputtext,
    .p-fluid .p-inputgroup .p-inputtext {
        width: 100%;
    }
    .padding-left-10 {
        padding-left: 10px;
    }
    .align-sub-text {
        vertical-align: sub;
    }
    .fc .fc-toolbar .fc-button {
        display: inline;
    }
    .fc-unthemed .fc-disabled-day {
        background: #d7d7d7 !important;
    }
    .fc-unthemed td.fc-today {
        background: #fcf8e3 !important;
        border-top: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
    }
    .fc td.fc-today {
        border-style: double;
    }
}
.fc-time-grid-event.fc-short .fc-time:before {
    content: "";
}
.fc-time-grid-event.fc-short .fc-time:after {
    content: "";
    padding-right: 0.1rem;
}
