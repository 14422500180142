.icon2 {
    font-size: 58px;
    color: #f0c040;
    margin-right: 4px;
}

.full2 {
    color: #f0c040; 
}
.pointer-schedule{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
#pr_id_30_content>h3{
    text-align: center;
}
.text-center {
    text-align: center;
}
.box-shadow {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 5px 0;
    border-radius: 20px;
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.2);
}
p.box-shadow:hover{
    background-color: #f1eaea;
}
.p-rating-icon {
    padding: 0 4px;
}
.p-rating .p-rating-icon {
    color: #f0c040 !important;
    font-size: 34px !important;
}
.p-rating .p-rating-icon.pi-star {
    color: #f0c040 !important;
    font-size: 34px !important;
}

