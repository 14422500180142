%btnGradient {
    background: rgb(247, 160, 36);
    background: linear-gradient(90deg, rgba(247, 160, 36, 1) 0%, rgba(239, 109, 34, 1) 55%, rgba(196, 56, 62, 1) 100%);
}
.login-wrap {
    color: #636464;
    background: no-repeat rgba(156, 156, 156, 0.808) url("../../assets/images/background-login.png") 100%;
    background-size: cover;
    min-height: 100vh;
    .login-html {
        min-width: 35rem;
        min-height: 35rem;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        position: absolute;
        padding: 8rem 5rem;
        border-radius: 1rem;
        background-color: #ffffffec;
        box-shadow: 0 12px 15px 0 rgba(119, 119, 119, 0.24), 0 17px 50px 0 rgba(121, 121, 121, 0.19);
    }

    .p-divider .p-divider-content {
        background-color: #f4f4f4 !important;
    }
    .p-float-label input:focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label textarea:focus ~ label,
    .p-float-label textarea.p-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
        // top: -.75rem;
        font-size: 17px;
    }
    .pass-relative {
        position: relative;
        z-index: 1;
        .watch-pass {
            i {
                color: rgb(196, 56, 62);
                color: linear-gradient(
                    90deg,
                    rgba(196, 56, 62, 1) 0%,
                    rgba(239, 109, 34, 1) 35%,
                    rgba(247, 160, 36, 1) 100%
                );
            }
            position: absolute;
            right: 8px;
            top: 10px;
            z-index: 2;
        }
    }

    .input-login,.input-login input {
        border-radius: 0px !important;
        border: none;
        border-bottom: 1px solid #c57275;
        background: none !important;
        height: 45px;
        border-image-slice: 1;
        border-image-source: linear-gradient(
            90deg,
            rgba(247, 160, 36, 1) 0%,
            rgba(239, 109, 34, 1) 55%,
            rgba(196, 56, 62, 1) 100%
        );
        &:focus {
            border-image-slice: 1;
            border-image-source: linear-gradient(
                90deg,
                rgba(196, 56, 62, 1) 0%,
                rgba(239, 109, 34, 1) 35%,
                rgba(247, 160, 36, 1) 100%
            );
            border-color: rgb(196, 56, 62);
            box-shadow: none;
        }
    }
    .input-login input{
        width: 100%!important;
    }

    .label-login {
        color: rgba(239, 109, 34, 1);
    }
    .btn-login {
        width: 100%;
        border-radius: 23px;
        border: none;
        background: none;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        color: rgba(196, 56, 62, 1);
        font-weight: 500;
        &:enabled:hover {
            background: rgb(247, 160, 36);
            background: linear-gradient(
                90deg,
                rgba(247, 160, 36, 1) 0%,
                rgba(239, 109, 34, 1) 55%,
                rgba(196, 56, 62, 1) 100%
            );
            color: white;
        }
    }
   

    .btn-log-gg {
        &:hover {
            background: rgb(247, 160, 36);
            background: linear-gradient(
                90deg,
                rgba(247, 160, 36, 1) 0%,
                rgba(239, 109, 34, 1) 55%,
                rgba(196, 56, 62, 1) 100%
            );
            color: white;
        }
        font-weight: 500;
        display: flex;
        height: 2.8rem;
        font-size: 17px;
        line-height: 1.2;
        border-radius: 23px;
        justify-content: center;
        align-items: center;
        transition: all 0.4s;
        .icon-google {
            height: 2.2rem;
            margin-right: 0.5rem;
        }
    }
}
