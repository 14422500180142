.layout-footer {
    color: #000000;
    
    height: var(--height-footer) !important;
    padding: 1.2rem 2rem;
    align-items: center;
    justify-content: space-between;
    background-color: var(--surface-a);
    a {
        color: #000000;
        font-weight: 600;
    }
    img {
        vertical-align: sub;
        color: var(--text-color);
    }
}
